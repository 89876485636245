// Project: GalaxyComplete
// Created: 9/28/20 by sammy
// File: GalaxyMigrateCommon

import * as React from "react";
import { GalaxyMigrateDeploymentDetails, GalaxyMigrateStorageConfig } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { CMC_MIGRATION_SUBROUTE, DEPLOYMENT_DETAILS_SUBROUTE, getProjectSubRouteTemplate, PROJECT_SUBROUTE } from "../app/AppRoutes";
import { PortNumbers } from "gc-web-proto/galaxymigratepb/galaxy_migrate_pb";
import { useAppServices } from "../app/services";
import { MTDIDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import { useDownloadFileFromURL } from "../../common/misc";
import { DeploymentHostEnvironment } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";

export const getGmStorageConfigDeviceTypeFromObject = (d: GalaxyMigrateStorageConfig.Device.AsObject) => {
    let deviceType = d.blockDevice?.deviceType;
    if (d.blockDevice?.fsType) {
        deviceType = `${deviceType} (${d.blockDevice.fsType})`;
    }
    return deviceType;
};
export const getGmStorageConfigDeviceType = (d: GalaxyMigrateStorageConfig.Device.AsObject) => {
    let deviceType = d.blockDevice.deviceType;
    if (d.blockDevice.fsType) {
        deviceType = `${deviceType} (${d.blockDevice.fsType})`;
    }
    return deviceType;
};

export const getGmStorageConfigDeviceFsInfo = (d: GalaxyMigrateStorageConfig.Device) => {
    let deviceType = d.getBlockDevice().getFsType() || "--";
    if (d.getBlockDevice().getLabel()) {
        deviceType = `${deviceType} | ${d.getBlockDevice().getLabel()}`;
    }
    if (d.getBlockDevice().getMountPoint()) {
        const mountPoint = d.getBlockDevice().getMountPoint();
        deviceType = `${deviceType} | ${mountPoint}`;
    }
    return deviceType;
};

export const useNavigateToDeploymentsList = () => {
    const { appGlobalService } = useAppServices();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.CMC_MIGRATIONS}/${CMC_MIGRATION_SUBROUTE.DEPLOYMENTS}`), { projectId });
    return () => {
        navigate(p);
        appGlobalService.setAppSubmenu(PROJECT_SUBROUTE.CMC_MIGRATIONS);
    };
};

export const useNavigateToDeploymentDetails = () => {
    const { appGlobalService } = useAppServices();
    const navigate = useNavigate();
    const { projectId } = useParams();
    return (deploymentId: string) => {
        const p = generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.CMC_MIGRATIONS}/${CMC_MIGRATION_SUBROUTE.DEPLOYMENTS}/${deploymentId}`), {
            projectId,
        });
        navigate(p);
        appGlobalService.setAppSubmenu(PROJECT_SUBROUTE.CMC_MIGRATIONS);
    };
};

export const useNavigateToDeploymentLiveLog = (deploymentId: string) => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const p = `${generateDeploymentDetailsPath(deploymentId, projectId)}/${DEPLOYMENT_DETAILS_SUBROUTE.LOG}`;
    return () => {
        navigate(p);
    };
};

export const useNavigateToDeploymentXrays = (deploymentId: string) => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const p = `${generateDeploymentDetailsPath(deploymentId, projectId)}/${DEPLOYMENT_DETAILS_SUBROUTE.XRAYS}`;
    return () => {
        navigate(p);
    };
};

export const useDownloadXray = () => {
    const { supportService } = useAppServices();
    const downloadFile = useDownloadFileFromURL();

    return async (xrayId: string) => {
        const res = await supportService.downloadXray(xrayId);
        if (res) {
            const url = res.downloadPath;
            const fileName = res.fileName;
            await downloadFile(url, fileName);
        }
    };
};

export const generateDeploymentDetailsPath = (deploymentId: string, projectId: string) => {
    return generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.CMC_MIGRATIONS}/${CMC_MIGRATION_SUBROUTE.DEPLOYMENTS}/${deploymentId}`), { projectId });
};

export const formatServerAddressWithoutDefaultPort = (address: string) => {
    return address.replace(`:${PortNumbers.REMOTE_GM_SERVER}`, "");
};

export const formatServerAddressWithoutPort = (address: string) => {
    return address ? address.split(":")[0] : null;
};

export const getSystemDevPathByNamePrefix = (d: GalaxyMigrateDeploymentDetails) => {
    return d?.getInfo()?.getOsType() === MTDIDeploymentInfo.OSType.WINDOWS ? "" : "/dev/";
};

export const getHostEnvDisplayName = (e: DeploymentHostEnvironment.DeploymentHostEnvironment): string => {
    if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.AWS) {
        return "AWS";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE) {
        return "Azure";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.GCP) {
        return "GCP";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.DO) {
        return "DigitalOcean";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE) {
        return "VMware";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.HYPERV) {
        return "Hyper-V";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.SOFTLAYER) {
        return "SoftLayer";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.OPENSTACK) {
        return "OpenStack";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.VULTR) {
        return "Vultr";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.UNKNOWN) {
        return "Unknown";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.NUTANIX) {
        return "Nutanix";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.OTHER) {
        return "On-Prem";
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.KVM) {
        return "KVM";
    } else {
        return null;
    }
};

export const getGalaxyMigrateHelperNodeOSName = (env: DeploymentHostEnvironment.DeploymentHostEnvironment) => {
    return `CMC Helper for ${getHostEnvDisplayName(env)}`;
};
