// ======================
// SupportDetailsDialogButton
// ======================

import { useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { Box, Dialog, IconButton, SvgIcon } from "@mui/material";
import { MdOutlineTroubleshoot } from "react-icons/md";
import React from "react";
import { BlackTooltip } from "../../common/tooltip/ColorTooltip";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { CodeCard } from "../../common/card/DarkCard";

interface SupportDetailsDialogButtonProps {
    json: any;
}

export const SupportDetailsDialogButton: React.FC<SupportDetailsDialogButtonProps> = (p) => {
    const { json } = p;
    const dialogState = useDialogState();
    const fullScreen = useShouldDialogFullScreen();

    return (
        <>
            <Box>
                <BlackTooltip title={"View Support Details"}>
                    <IconButton onClick={dialogState.open}>
                        <SvgIcon>
                            <MdOutlineTroubleshoot />
                        </SvgIcon>
                    </IconButton>
                </BlackTooltip>
            </Box>
            {dialogState.isOpen && (
                <Dialog maxWidth={"md"} fullWidth fullScreen={fullScreen} open={dialogState.isOpen} onClose={dialogState.close}>
                    <DialogTopBar dialogState={dialogState} title={"Support Details"} />
                    <Box p={2}>
                        <CodeCard>
                            <Box p={2}>
                                <pre>{json}</pre>
                            </Box>
                        </CodeCard>
                    </Box>
                </Dialog>
            )}
        </>
    );
};
